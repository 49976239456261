import { DatePicker, Flex, Select, Typography } from "antd";
import dayjs from "dayjs";
import { useAtom, useAtomValue } from "jotai";
import { filtersAtom, optionsAtom } from "./atoms";

export const Filters = () => {
	const [filters, setFilters] = useAtom(filtersAtom);
	const { people, epics, sprints, issues } = useAtomValue(optionsAtom);
	return (
		<Flex gap={12}>
			<div style={{ width: "100%" }}>
				<Typography.Text>Filter by person</Typography.Text>
				<Select
					value={filters.person}
					options={people}
					allowClear
					style={{ width: "100%" }}
					onChange={(person) => setFilters({ ...filters, person })}
				/>
			</div>
			<div style={{ width: "100%" }}>
				<Typography.Text>Filter by sprint</Typography.Text>
				<Select
					value={filters.sprint}
					options={sprints}
					allowClear
					style={{ width: "100%" }}
					onChange={(sprint) => setFilters({ ...filters, sprint })}
				/>
			</div>
			<div style={{ width: "100%" }}>
				<Typography.Text>Filter by epic</Typography.Text>
				<Select
					value={filters.epic}
					options={epics}
					allowClear
					style={{ width: "100%" }}
					onChange={(epic) => setFilters({ ...filters, epic })}
				/>
			</div>
			<div style={{ width: "100%" }}>
				<Typography.Text>Filter by issue</Typography.Text>
				<Select
					value={filters.issue}
					options={issues}
					allowClear
					style={{ width: "100%" }}
					onChange={(issue) => setFilters({ ...filters, issue })}
				/>
			</div>
			<div style={{ width: "100%" }}>
				<Typography.Text>Filter by weeks</Typography.Text>
				<DatePicker.RangePicker
					picker="week"
					value={[
						filters.fromDate ? dayjs(filters.fromDate) : undefined,
						filters.toDate ? dayjs(filters.toDate) : undefined,
					]}
					allowClear
					style={{ width: "100%" }}
					onChange={(x) =>
						setFilters({
							...filters,
							fromDate: x?.[0]?.startOf("w").toDate(),
							toDate: x?.[1]?.endOf("w").toDate(),
						})
					}
				/>
			</div>
		</Flex>
	);
};
