import { Progress, Space, Tag } from "antd";
import { useAtomValue } from "jotai";
import { issuesKpisAtom } from "./atoms";

export const Kpis = () => {
	const {
		issuesCount,
		worklogsCount,
		worklogsWithCommentCount,
		worklogsSeconds,
	} = useAtomValue(issuesKpisAtom);
	const percentWithComment = Math.round(
		(worklogsWithCommentCount / worklogsCount) * 100,
	);
	return (
		<Space>
			<Tag>{issuesCount} issues</Tag>
			<Tag>
				{worklogsCount} worklogs ({worklogsWithCommentCount} with comment){" "}
				<Progress percent={percentWithComment} style={{ width: 100 }} />
			</Tag>
			<Tag>{(worklogsSeconds / 3600).toFixed(1)} hours</Tag>
		</Space>
	);
};
