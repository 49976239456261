import { Flex } from "antd";
import { CalHeatmap } from "./CalHeatmap";
import { Filters } from "./Filters";
import { Latest } from "./Latest";
import { RefreshButton } from "./RefreshButton";
import { Tops } from "./Tops";

export const App = () => (
	<Flex vertical gap={10}>
		<Filters />
		<CalHeatmap />
		<Tops />
		<Latest />
		<RefreshButton />
	</Flex>
);
