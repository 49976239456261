import { keepPreviousData } from "@tanstack/react-query";
import dayjs from "dayjs";
import { isEqual } from "es-toolkit";
import type { InferResponseType } from "hono";
import { atom, createStore } from "jotai";
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query";
import { selectAtom } from "jotai/utils";
import { client, queryClient } from "./client";

export const store = createStore();
type CachedIssues = InferResponseType<typeof client.issuesWithFilter.$post>;
type Kpis = CachedIssues["kpis"];
type Options = CachedIssues["options"];
type WorklogsByDay = CachedIssues["worklogsByDay"];
type Tops = CachedIssues["tops"];
export const issuesAtom = atomWithQuery(
	(get) => {
		const filters = get(filtersAtom);
		return {
			queryKey: ["issues", filters],
			queryFn: () =>
				client.issuesWithFilter.$post({ json: filters }).then((x) => x.json()),
			placeholderData: keepPreviousData,
		};
	},
	() => queryClient,
);
export const refreshAtom = atomWithMutation(
	() => ({
		mutationFn: () => client.refresh.$post(),
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["issues"] }),
	}),
	() => queryClient,
);
export const isLoadingAtom = atom(
	(get) => get(issuesAtom).isFetching || get(refreshAtom).isPending,
);
export const issuesUpdatedAtAtom = selectAtom(
	issuesAtom,
	(x) => x.data?.updatedAt,
	isEqual,
);
export const filtersAtom = atom({
	person: undefined as string | null | undefined,
	epic: undefined as string | null | undefined,
	sprint: undefined as string | null | undefined,
	issue: undefined as string | null | undefined,
	fromDate: dayjs().subtract(3, "w").startOf("w").toDate() as
		| Date
		| null
		| undefined,
	toDate: dayjs().endOf("w").toDate() as Date | null | undefined,
});
export const issuesOnlyAtom = selectAtom(
	issuesAtom,
	(x) => x?.data?.issues ?? [],
	isEqual,
);
export const minDateAtom = selectAtom(
	issuesAtom,
	(x) => (x?.data?.minDate ? new Date(x.data.minDate) : new Date()),
	isEqual,
);
export const issuesKpisAtom = selectAtom(
	issuesAtom,
	(x): Kpis =>
		x.data?.kpis ?? {
			issuesCount: 0,
			worklogsCount: 0,
			worklogsWithCommentCount: 0,
			worklogsSeconds: 0,
		},
	isEqual,
);
export const optionsAtom = selectAtom(
	issuesAtom,
	(x): Options =>
		x?.data?.options ?? {
			epics: [],
			sprints: [],
			people: [],
			issues: [],
		},
	isEqual,
);
export const worklogsByDayAtom = selectAtom(
	issuesAtom,
	(x): WorklogsByDay => x?.data?.worklogsByDay ?? [],
	isEqual,
);
export const topsAtom = selectAtom(
	issuesAtom,
	(x): Tops =>
		x?.data?.tops ?? {
			issues: [],
			epics: [],
			sprints: [],
			people: [],
			worklogs: [],
		},
	isEqual,
);
export const latestAtom = selectAtom(
	issuesAtom,
	(x) =>
		x?.data?.latest ?? {
			comments: [],
			worklogs: [],
		},
	isEqual,
);
