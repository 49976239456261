import { QueryClient } from "@tanstack/react-query";
import { hc } from "hono/client";
import type { ServerType } from "../../server/server";

const url =
	location.host === "localhost:9000"
		? "http://localhost:3000/"
		: `${location.origin}/`;
console.log(`url: ${url}`);

export const client = hc<ServerType>(url, {
	headers: () => ({
		authorization:
			new URLSearchParams(location.search).get("authorization") ?? "",
	}),
});
export const queryClient = new QueryClient();
