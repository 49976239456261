import { Card, Flex, Typography } from "antd";
import { useAtom, useAtomValue } from "jotai";
import { filtersAtom, topsAtom } from "./atoms";

type DisplayTopProps = {
	title: string;
	tops: { key: string | null; label: string; value: number; link?: string }[];
	hasValue?: boolean;
	onSelect?: (value: string | null) => void;
	onDelete?: () => void;
};
const DisplayTop = ({ title, tops, onSelect, onDelete }: DisplayTopProps) => (
	<Card
		style={{ flex: 1 }}
		styles={{ body: { maxHeight: 400, overflowY: "auto" } }}
		title={
			<>
				Top {tops.length} {title}
				{onDelete && (
					<>
						{" "}
						<Typography.Link type="danger" onClick={onDelete}>
							Clear
						</Typography.Link>
					</>
				)}
			</>
		}
	>
		{tops.map((x) => (
			<div key={x.key}>
				<b>{(x.value / 3600).toFixed(1)}h</b>{" "}
				{onSelect ? (
					<Typography.Link onClick={() => onSelect(x.key)}>
						{x.label}
					</Typography.Link>
				) : (
					<Typography.Text>{x.label}</Typography.Text>
				)}
				{x.link && (
					<Typography.Link href={x.link} target="_blank">
						{" "}
						🔗
					</Typography.Link>
				)}
			</div>
		))}
	</Card>
);

export const Tops = () => {
	const [filters, setFilters] = useAtom(filtersAtom);
	const tops = useAtomValue(topsAtom);
	return (
		<Flex justify="space-between" gap={15}>
			<DisplayTop
				title="people"
				tops={tops.people}
				onSelect={(person) => setFilters({ ...filters, person })}
				onDelete={
					filters.person
						? () => setFilters({ ...filters, person: undefined })
						: undefined
				}
			/>
			<DisplayTop
				title="sprints"
				tops={tops.sprints}
				onSelect={(sprint) => setFilters({ ...filters, sprint })}
				onDelete={
					filters.sprint !== undefined
						? () => setFilters({ ...filters, sprint: undefined })
						: undefined
				}
			/>
			<DisplayTop
				title="epics"
				tops={tops.epics}
				onSelect={(epic) => setFilters({ ...filters, epic })}
				onDelete={
					filters.epic !== undefined
						? () => setFilters({ ...filters, epic: undefined })
						: undefined
				}
			/>
			<DisplayTop
				title="issues"
				tops={tops.issues}
				onSelect={(issue) => setFilters({ ...filters, issue })}
				onDelete={
					filters.issue
						? () => setFilters({ ...filters, issue: undefined })
						: undefined
				}
			/>
			<DisplayTop title="worklogs" tops={tops.worklogs} />
		</Flex>
	);
};
