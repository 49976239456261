import "antd/dist/reset.css";
import { QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { Provider } from "jotai";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { FullScreenLayout } from "./FullScreenLayout";
import { store } from "./atoms";
import { queryClient } from "./client";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", { weekStart: 1 });

const container = document.querySelector("#root");
if (!container) {
	throw new Error("No root element found");
}
const root = createRoot(container);

root.render(
	<QueryClientProvider client={queryClient}>
		<Provider store={store}>
			<FullScreenLayout>
				<App />
			</FullScreenLayout>
		</Provider>
	</QueryClientProvider>,
);
