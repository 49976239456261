import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import dayjs from "dayjs";
import { useAtomValue } from "jotai";
import { isLoadingAtom, issuesUpdatedAtAtom, refreshAtom } from "./atoms";

export const RefreshButton = () => {
	const refresh = useAtomValue(refreshAtom);
	const isLoading = useAtomValue(isLoadingAtom);
	const updatedAt = useAtomValue(issuesUpdatedAtAtom);
	return (
		<FloatButton
			onClick={() => refresh.mutate()}
			icon={isLoading ? <LoadingOutlined /> : <ReloadOutlined />}
			type={isLoading ? "default" : "primary"}
			tooltip={
				updatedAt &&
				`Last update: ${dayjs(updatedAt).format("YYYY/MM/DD HH:mm:ss")}`
			}
		/>
	);
};
