import HeatMap from "@uiw/react-heat-map";
import dayjs from "dayjs";
import { useAtom, useAtomValue } from "jotai";
import { useCallback } from "react";
import { RectRender } from "./RectRender";
import { filtersAtom, minDateAtom, worklogsByDayAtom } from "./atoms";

export const CalHeatmap = () => {
	const minDate = useAtomValue(minDateAtom);
	const worklogsByDay = useAtomValue(worklogsByDayAtom);
	const [filters, setFilters] = useAtom(filtersAtom);
	const handleOnClick = useCallback(
		(date: string) => {
			if (filters.fromDate && filters.toDate) {
				setFilters({ ...filters, fromDate: undefined, toDate: undefined });
				return;
			}
			const _date = dayjs(date);
			setFilters({
				...filters,
				fromDate: _date.startOf("day").toDate(),
				toDate: _date.endOf("day").toDate(),
			});
		},
		[filters, setFilters],
	);
	return (
		<HeatMap
			value={worklogsByDay}
			startDate={minDate}
			legendCellSize={0}
			style={{ marginBottom: -35 }}
			rectRender={(props, data) => (
				<RectRender
					props={props}
					content={data.content}
					onClick={() => handleOnClick(data.date)}
				/>
			)}
		/>
	);
};
