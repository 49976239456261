import Tooltip from "@uiw/react-tooltip";

type RectRenderProps = {
	props: React.SVGProps<SVGRectElement>;
	content: React.ReactNode;
	onClick?: () => void;
};
export const RectRender = ({ props, content, onClick }: RectRenderProps) =>
	content ? (
		<Tooltip placement="top" content={content}>
			<rect {...props} onClick={onClick} />
		</Tooltip>
	) : (
		<rect {...props} onClick={onClick} />
	);
