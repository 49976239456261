import { Card, Flex, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import { words } from "es-toolkit";
import { useAtomValue } from "jotai";
import { latestAtom } from "./atoms";

export const Latest = () => {
	const latest = useAtomValue(latestAtom);
	return (
		<Flex gap={15}>
			<Card
				style={{ flex: 1 }}
				styles={{ body: { maxHeight: 400, overflowY: "auto" } }}
				title={`Latest ${latest.comments.length} comments`}
			>
				{latest.comments.map((x) => (
					<div key={x.id}>
						<Typography.Text>
							<i>{dayjs(x.updated).format("YYYY-MM-DD HH:mm")}</i>{" "}
							<Tooltip title={x.issueSummary}>
								<b>{x.issueKey}</b>
							</Tooltip>{" "}
							<Tooltip title={x.author}>
								<u>
									{x.author
										? words(x.author)
												.map((x) => x[0])
												.join("")
										: "❓"}
								</u>
							</Tooltip>
							: {x.body}
						</Typography.Text>
						{x.link && (
							<Typography.Link href={x.link} target="_blank">
								{" "}
								🔗
							</Typography.Link>
						)}
					</div>
				))}
			</Card>
			<Card
				style={{ flex: 1 }}
				styles={{ body: { maxHeight: 400, overflowY: "auto" } }}
				title={`Latest ${latest.worklogs.length} worklogs`}
			>
				{latest.worklogs.map((x, i) => (
					<div key={`${x.issueKey}${i}`}>
						<Typography.Text>
							<i>{dayjs(x.started).format("YYYY-MM-DD HH:mm")}</i>{" "}
							<Tooltip title={x.issueSummary}>
								<b>{x.issueKey}</b>
							</Tooltip>{" "}
							<Tooltip title={x.author}>
								<u>
									{x.author
										? words(x.author)
												.map((x) => x[0])
												.join("")
										: "❓"}
								</u>
							</Tooltip>{" "}
							({((x.timeSpentSeconds ?? 0) / 3600).toFixed(1)}h) : {x.comment}
						</Typography.Text>
						{x.link && (
							<Typography.Link href={x.link} target="_blank">
								{" "}
								🔗
							</Typography.Link>
						)}
					</div>
				))}
			</Card>
		</Flex>
	);
};
